













































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { SystemSettingInfoProperties } from "./SystemSettingInfoView.vue";
import AutoNumericUtil from "@/utilities/AutoNumericUtil";
import { Options } from "autonumeric";

@Component({
    data: () => ({
        fullNamePicker: false,
        messagePicker: false,
        backgroundPicker: false,
        tagPicker: false
    })
})
export default class SystemSettingLiveSessionView extends Vue {
    @Prop() private properties: SystemSettingInfoProperties;
    private inputProductWeight: HTMLInputElement;

    public get systemSetting() {
        return this.properties.systemSetting;
    }

    public get liveSession() {
        return this.systemSetting.liveSession;
    }

    public get standardProductPrices() {
        const t = this.liveSession.standardProductPrices ?? [];

        if (t.length < 9) {
            for (var i = 0; i < 9; i++) {
                if (!t[i]) t[i] = { slot: (i + 1), price: 0 };
            }
        }

        return t;
    }

    public get column1() {
        return this.standardProductPrices.filter(x => x.slot % 3 === 1);
    }

    public get column2() {
        return this.standardProductPrices.filter(x => x.slot % 3 === 2);
    }

    public get column3() {
        return this.standardProductPrices.filter(x => x.slot % 3 === 0)
    }

    public get fullNameStyle() {
        return {
            backgroundColor: this.liveSession.fullNameColor,
            cursor: "pointer",
            height: "25px",
            width: "25px",
            borderRadius: this.$data.fullNamePicker ? "50%" : "4px",
            transition: "border-radius 200ms ease-in-out"
        };
    }

    public get messageStyle() {
         return {
            backgroundColor: this.liveSession.messageColor,
            cursor: "pointer",
            height: "25px",
            width: "25px",
            borderRadius: this.$data.messagePicker ? "50%" : "4px",
            transition: "border-radius 200ms ease-in-out"
        };
    }

    public get backgroundStyle() {
         return {
            backgroundColor: this.liveSession.backgroundColor,
            cursor: "pointer",
            height: "25px",
            width: "25px",
            borderRadius: this.$data.backgroundPicker ? "50%" : "4px",
            transition: "border-radius 200ms ease-in-out"
        };
    }

    public get tagStyle() {
         return {
            backgroundColor: this.liveSession.tagColor,
            cursor: "pointer",
            height: "25px",
            width: "25px",
            borderRadius: this.$data.tagPicker ? "50%" : "4px",
            transition: "border-radius 200ms ease-in-out"
        };
    }

    public mounted() {
        this.properties.events.subscribe('update-formatter', this.updateFormatter);
        this.inputProductWeight = this.$el.querySelector("#product-weight");

        this.updateFormatter();
    }

    public destroyed() {
        this.properties.events.remove('update-formatter', this.updateFormatter);
    }

    public updateFormatter() {
        setTimeout(() => {
            if (this.liveSession) {
                const options: Options = {
                    minimumValue: "0",
                    historySize: 0,
                    outputFormat: "number",
                    digitGroupSeparator: "",
                    unformatOnHover: false,
                    unformatOnSubmit: false
                };

                AutoNumericUtil.findOrNew(this.inputProductWeight, null, options)
                    .set(this.liveSession.productWeight ?? 0);
                
                const standardProductPrices = this.$el.querySelectorAll(".standard-product-price input");
                for (var i = 0; i < standardProductPrices.length; i++) {
                    const p = <HTMLInputElement>standardProductPrices[i];
                    const s = Number(p.getAttribute("slot"));
                    const t = this.standardProductPrices.find(x => x.slot === s);
                    AutoNumericUtil.findOrNew(p, null, options)
                        .set(t.price ?? 0);
                }
            }
        }, 150);
    }
}
